import React, { Component, createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../actions";
import { Button, Badge,  Alert, AlertContext } from "@cargo/ui-kit";
import _ from 'lodash';
import { FRONTEND_DATA, PublishState } from "../globals";
import { ydoc } from "../lib/multi-user";
import { withRouter} from 'react-router-dom';
import { wsProvider } from "../lib/multi-user";
import { ContextMenu, MenuContext } from "@cargo/common/context-menu";
import { IntercomButton } from '@cargo/common/intercom';
import { paths } from "../router";
import { uploadMedia } from '../lib/media';
import { getSupportedFileTypes } from './ui-kit/helpers';
import { HotKey, HotKeyProxy, CommerceRightMenuBarButton } from "./ui-kit";
import { isProductionTestingAcct } from "@cargo/common/helpers";

import * as Sentry from "@sentry/browser";

// Icons
import PageDesignOptionsIcon from "../svg-icons/page-design-options.svg";
import SiteStylesIcon from "../svg-icons/site-styles.svg";
// import CodeViewIcon from "../svg-icons/code-view.svg";
import GearIcon from "../svg-icons/settings.svg";
import CommerceIcon from "../svg-icons/commerce.svg";
import PublishingIcon from "../svg-icons/publishing.svg";
import CollaborateIcon from "../svg-icons/collaborate.svg";
import TextColorIcon from "../svg-icons/formatting-textcolor.svg";

let proxiedModal;
let proxiedMenu;

let isDev = CARGO_ENV !== 'production';

class RightMenuBar extends Component {

	constructor(props) {

		super(props);

		this.state = {
			users: [],
			isDraggingOverMedia: false,
			isSupportUser: false,
			newsletterButtonComponent: null,
			showNewsletterButton: false,
		}

		this.settingsButtonsRef = createRef();
	}

	componentDidMount() {
		
		// get the users data
		wsProvider.on('awarenessUpdate', (data) => {
			this.getMultiUserData();
		});

		// get initial data
		this.getMultiUserData();


		if( isDev || isProductionTestingAcct(this.props.user?.id, 'newsletter') ){
			this.importNewsletterButton();
		}

	}

	componentDidUpdate(prevProps, prevState) {

		if (
			this.props.user !== prevProps.user
			|| this.props.editors !== prevProps.editors
		) {

			this.setState({
				isSupportUser: _.some(this.props.editors, editor => editor.id === this.props.user.id && editor.role === "Support")
			});

			this.getMultiUserData();

			if( isDev || isProductionTestingAcct(this.props.user?.id, 'newsletter') ) {
				this.importNewsletterButton();
			}
		}

		if( this.props.commerceWindowOpen !== prevProps.commerceWindowOpen 
			&& !this.props.productFetchRequired
		 ){
			this.props.updateAdminState({
				productFetchRequired: true
			})
		}

	}

	importNewsletterButton = () => {
		const { user, site } = this.props;
		const userId = user?.id || false;

		const shouldImport = (
			// Condition for development environment:
			(isDev && (
				user?.template_editor === true &&
				site?.display_url === "newsletter.dev.cargo.site"
			)) ||
			// Condition for production testing accounts:
			(!isDev && (
				isProductionTestingAcct(userId, 'newsletter') && 
				site?.display_url === 'newsletter.cargo.site' 
			))
		);

		if (shouldImport) {
			const { newsletterButtonComponent } = this.state;

			if ( newsletterButtonComponent ) {
				// Button already imported; just show it.
				this.setState({ showNewsletterButton: true });
			} else {
				// Import and then show button.
				import("./newsletter-button").then(({ default: loadedComponent }) => {
					this.setState({
						newsletterButtonComponent: loadedComponent,
						showNewsletterButton: true
					});
				});
			}
		}
	}

	hasOpenWindow(id) {

		return this.props.activeUIWindows
			.filter(UIWindow => UIWindow.id === id)
			.length > 0
	}

	isEditingPage() {
		return this.props.PIDBeingEdited
	}

	getMultiUserData() {

		if(!this.props.user.id) {
			return;
		}
		
		let otherUsers = [];
		let ownUsers = [];

		wsProvider.awareness.getStates().forEach(({user, hasCommerceOpen}) => {

			if(!user) {
				return;
			}

			const editor = _.find(this.props.editors, (editor) => { 
				return editor.id === user.cargoId
			});

			if(editor && editor.role !== 'Support') {

				if(user.cargoId === this.props.user.id){
					ownUsers.push(user);
				} else {
					otherUsers.push(user);
				}
			}
			
		});

		// only show icons if other users are present
		if(otherUsers.length === 0) {
			
			this.setState({
				users: []
			})

			return;

		}

		this.setState({
			users: [
				..._.uniqBy(otherUsers, 'cargoId'),
				..._.uniqBy(ownUsers, 'cargoId')
			]
		});

	}

	openPublishingWindow = (e = undefined) => {
		
		this.toggleUIWindow(e, {
			component: import('./right-menu-bar/publish-settings-window'),
			componentName: 'publish-settings-window',
			windowName: 'site-settings',
			uiWindowType: 'popover',
			autoHeight: true,
			removeAll: true,
			removeGroup: false,
			positionType: 'fixed-right'
		});

	}

	toggleUIWindow = async (event, options) => {

		const buttonPos = event ? event.currentTarget?.getBoundingClientRect?.() : document.querySelector('#admin #right-menu-bar button[tooltip="Help"]')?.getBoundingClientRect();

		let group = options.group === 'help' ? 'help-window': 'right-menu-bar';

		await this.props.addUIWindow({
			group: group,
			component: options.component,
			id: options.componentName,
			props: {
				type: options.uiWindowType, 
				positionType: options.positionType, 
				borderRadius: options.borderRadius, 
				autoHeight: options.autoHeight, 
				buttonPos: _.merge({}, buttonPos), 
				tabbed: options.tabbed,
				windowName: options.windowName,
				clickoutLayer: options.clickoutLayer,
				clickoutLayerDim: options.clickoutLayerDim,
				preventClickout: options.preventClickout,
				preventEsc: options.preventEsc,
				acceptDrops: options.acceptDrops,
				draggingUploadedImage: false,
				disableDragging: options.disableDragging,
				supportsMobile: options.supportsMobile,
				closeButton: options.closeButton,
				legacyWindow: options.legacyWindow,
				styles: options.styles,
				proxiedModal: options.proxiedModal,
				proxiedMenu: options.proxiedMenu,
				ignoreClickout: options.ignoreClickout,
				requireDiscreteClose: options.requireDiscreteClose,
				openToChat: options.openToChat,
			}
		},{
			removeGroup: options.removeGroup ?? true,
			// removeGroupByName: 'formatting',
			removeAll: options.removeAll ?? false 
		});
		
		// Always remove the color picker. 
		this.props.removeUIWindow(uiWindow => { 
			return uiWindow.id === 'color-palette' 
		});

		if (options.uiWindowType === 'popover' && options.removeGroup !== false && options.removeAll !== false) {

			this.props.removeUIWindow(uiWindow => {
				return uiWindow.id !== options.componentName && uiWindow.group === 'right-menu-bar' || uiWindow.group === 'main' || uiWindow.id === "page-code-view-window";
			});

		}


	}

	hasActiveWindow(windowName) {
		return _.filter(this.props.openUIWindows, {id: windowName}).length !== 0
	}

	getActiveWindowName() {
		const currentPageOptionsUIWindow = this.props.openUIWindows[0];
		return _.get(currentPageOptionsUIWindow, 'id')?.replace('right-menu-bar/','');
	}

	render() {

		const viewport = _.get(this.props.adminState, 'viewport');
		const isViewportMobile = viewport === 'mobile' ? true : false ;

		let otherEditor = null;
		const currentEditor = this.props.editors.find(editor => editor.id === this.props.user.id);

		// always allow support access to Commerce
		if(currentEditor?.role !== 'Support') {
			
			wsProvider.awareness.getStates().forEach(state => {

				if(
					// this state is using commerce
					state?.hasCommerceOpen === true
					// and is not our own state
					&& state !== wsProvider.awareness.getLocalState()
				) {
					otherEditor = this.props.editors.find(editor => editor.id === state.user.cargoId)
				}

			});

		}

		const EditorList = () => {
			return(
				<>
					{this.state.users.map((user, index)=> {

						const editor = _.find(this.props.editors, (editor) => { 
							return editor.id === user.cargoId
						});

						if(!editor) {
							return null;
						}

						return (
							<div className="editor-avatar" key={user.cargoId+index} user-id={`${user.cargoId}`}>
								<Badge 
									user={{...editor, ...user}}
									tooltip={'email'}
									onMouseDown={(e) => {
										this.toggleUIWindow(e, {
											component: import('./right-menu-bar/editors-window'),
											componentName: 'editors-window',
											uiWindowType: 'popover',
											windowName: 'editors',
											clickoutLayer: true,
											clickoutLayerDim: true,
											positionType: 'center',
											removeGroup: false,
											removeAll: false
										})
									}}
								/>
							</div>
						)

					})}
				</>
			)
		}

		return (
			<div id="right-menu-bar">

				<MenuContext.Consumer>
					{
						menu => {
							proxiedMenu = menu;
						}

					}
				</MenuContext.Consumer>

				<AlertContext.Consumer>
					{
						modal => {
							proxiedModal = modal;
						}
					}
				</AlertContext.Consumer>
				{/* <PageOptions /> */}
				<div id="edge-filler" className="top"></div>
				<div id="settings-buttons" ref={this.settingsButtonsRef}>

					<Button 
						className={`${this.hasActiveWindow('publish-settings-window') ? 'popover-open' : ''} square publish-settings-button`}
						uiWindowIsOpen = { this.hasActiveWindow('publish-settings-window') }
						icon={<PublishingIcon />}
						onMouseDown={(e) => {
							// don't lose focus in editor
							e.preventDefault();
							this.openPublishingWindow(e);
						}}
						publish-state={this.props.hasPendingChanges || this.props.publishingAnimationActive ? "queued" : "unavailable" }
						tooltip="Publish Settings"
					/>


					<Button 
						label={<PageDesignOptionsIcon />}
						className={`${this.hasActiveWindow('page-settings-window-controller') ? 'popover-open' : ''}`}
						uiWindowIsOpen = { this.hasActiveWindow('page-settings-window-controller') }
						onMouseDown={(e) => {
							// don't lose focus in editor
							e.preventDefault();

							this.toggleUIWindow(e, {
								component: import('./right-menu-bar/page-settings-window-controller'),
								componentName: 'page-settings-window-controller',
								uiWindowType: 'popover',
								positionType: 'fixed-right',
								tabbed: false,
								styles: 'site',
								windowName: 'page-settings-window-controller',
							})
						}}
						tooltip="Default Page Settings"
					/>

					<Button 
						label={<TextColorIcon />}
						className={`${this.hasActiveWindow('text-styles-window') ? 'text-styles-button popover-open' : ''}`}
						uiWindowIsOpen = { this.hasActiveWindow('text-styles-window') }
						onMouseDown={(e) => {
							// don't lose focus in editor
							e.preventDefault();

							this.toggleUIWindow(e, {
								component: import('./right-menu-bar/text-styles-window'),
								componentName: 'text-styles-window',
								uiWindowType: 'popover',
								positionType: 'fixed-right',
								windowName: 'text-styles',
							})
						}}
						tooltip="Text Styles"
					/>

					<Button 
						label={<SiteStylesIcon />}
						className={`${this.hasActiveWindow('site-settings-window') ? 'popover-open' : ''}`}
						uiWindowIsOpen = { this.hasActiveWindow('site-settings-window') }
						onMouseDown={(e) => {
							// don't lose focus in editor
							e.preventDefault();

							this.toggleUIWindow(e, {
								component: import('./right-menu-bar/site-settings-ui'),
								componentName: 'site-settings-window',
								uiWindowType: 'popover',
								positionType: 'fixed-right',
								tabbed: false,
								styles: 'site',
								windowName: 'site-settings-window',
							})
						}}
						tooltip="Site Settings"
					/>

					<HotKey shortcut="cmd+shift+i" boundTo="all" callback={(e) => {

						e.preventDefault();

						this.toggleUIWindow(e, {
							component: import('./right-menu-bar/css-editor-window'),
							componentName: 'css-editor-window',
							uiWindowType: 'code-pane',
							positionType: 'fixed-right',
							windowName: 'css-editor',
							removeAll: true,
							closeButton: true,
							tabbed: true,
							ignoreClickout: true,
						})

					}} />

					{/* <Button 
						label={<CodeViewIcon />}
						button-name="css-editor-button"
						// button-state={this.isEditingPage() === false ? 'unavailable' : ''}
						className={`${this.hasActiveWindow('css-editor-window') ? 'popover-open' : ''}`}
						uiWindowIsOpen = { this.hasActiveWindow('css-editor-window') }
						onMouseDown={(e) => {
							// don't lose focus in editor
							e.preventDefault();

							this.toggleUIWindow(e, {
								component: import('./right-menu-bar/css-editor-window'),
								componentName: 'css-editor-window',
								uiWindowType: 'code-pane',
								positionType: 'fixed-right',
								windowName: 'css-editor',
								removeAll: true,
								closeButton: true,
								tabbed: true,
								ignoreClickout: true,
							})
						}}
						tooltip="CSS / HTML"
					/> */}

					<AlertContext.Consumer>
						{(Alert) => (
							<CommerceRightMenuBarButton 
								className={`${this.props.matchedRoute === paths.COMMERCE ? '' : ''} square ${this.props.matchedRoute !== paths.COMMERCE && otherEditor ? ' blocked' : ''}`}
								uiWindowIsOpen = { this.props.matchedRoute === paths.COMMERCE }
								icon={<CommerceIcon />}
								button-name="commerce"
								updateAdminState={this.props.updateAdminState}
								productFetchRequired={this.props.productFetchRequired}
								onMouseDown={(e) => {

									if(this.props.matchedRoute !== paths.COMMERCE) {
										
										let editorUseName = otherEditor?.full_name && otherEditor?.full_name?.length > 0 ? otherEditor?.full_name : otherEditor?.email.split('@')[0];
										// in use elsewhere
										if(otherEditor) {
												Alert.openModal({
													header: `Commerce is currently in use by ${editorUseName}.`, 
													type: 'notice',
													HotKeyProxy: HotKeyProxy,
													onConfirm: (options) => {},
													onClose: () => {}
											   })
										} else {
											// open
											this.props.history.push('/commerce');
										}


									} else {
										// close tryout window
										this.props.removeUIWindow(uiWindow => {
											return uiWindow.props.isCommerceTryoutWindow;
										});

									}

								}}
								tooltip="Commerce"
							/>
						)}
					</AlertContext.Consumer>


					{( this.state.newsletterButtonComponent && this.state.showNewsletterButton ) ? 
						<AlertContext.Consumer>
							{(Alert) => React.createElement(this.state.newsletterButtonComponent, { Alert })}
						</AlertContext.Consumer>
						:
						null
					}

				</div>

				<div className="background-fill"></div>
				<div className="background-fill"></div>

				<div className="bottom-buttons">

					{this.state.isSupportUser || this.state.users.length > 1 ? 
						<button-area className="users">
						{/* ${this.state.users.length >= 5 ? 'condensed' : ''} */}
							<div className={`editors condensed`}>
								<EditorList/> 
							</div>
							<Button 
								className={`users`}
								uiWindowIsOpen = { this.hasActiveWindow('editors') }
								icon={<CollaborateIcon />}
								onMouseDown={(e) => {
									this.toggleUIWindow(e, {
										component: import('./right-menu-bar/editors-window'),
										componentName: 'editors-window',
										uiWindowType: 'popover',
										windowName: 'editors',
										clickoutLayer: true,
										clickoutLayerDim: true,
										positionType: 'center',
										disableDragging: true,
										removeGroup: false,
										removeAll: false
									})
								}}
								tooltip="Users"
							/>
						</button-area>
					: 
						<Button 
							className={`users`}
							uiWindowIsOpen = { this.hasActiveWindow('editors') }
							icon={<CollaborateIcon />}
							onMouseDown={(e) => {
								this.toggleUIWindow(e, {
									component: import('./right-menu-bar/editors-window'),
									componentName: 'editors-window',
									uiWindowType: 'popover',
									windowName: 'editors',
									clickoutLayer: true,
									clickoutLayerDim: true,
									positionType: 'center',
									disableDragging: true,
									removeGroup: false,
									removeAll: false
								})
							}}
							tooltip="Users"
						/>
					}

					<AlertContext.Consumer>
						{(Alert) => (
							<IntercomButton 
								userData={this.props.user} 
								hasAnnexUpgrade={this.props.site.has_annex_upgrade} 
								location={'c3-admin'} 
								sentry={Sentry}
								Alert={Alert}
							/>
						)}
					</AlertContext.Consumer>

					{
						CARGO_ENV === "localhost" && <Button 
							label={<GearIcon />}
							button-name="local-dev-manager"
							uiWindowIsOpen = { this.hasActiveWindow('local-dev-manager') }
							onMouseDown={(e) => {
								this.toggleUIWindow(e, {
									component: import('./right-menu-bar/local-dev-manager'),
									componentName: 'local-dev-manager',
									windowName: 'local-dev-manager',
									uiWindowType: 'popover',
									clickoutLayer: true,
									clickoutLayerDim: true,
									positionType: 'center',
									disableDragging: true,
									removeGroup: false,
									removeAll: false
								})
							}}
							// tooltip="Modify local dev"
						/>
					}

				</div>

				<div id="edge-filler"></div>

			</div>
		);

	}

}

function mapReduxStateToProps(state, ownProps) {	

	return {
		user: state.user,
		editors: state.site.editors || [],
		site: state.site,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		PageBeingEditedData: state.pages.byId[state.frontendState.PIDBeingEdited],
		allWindows: state.uiWindows,
		openUIWindows: _.map(state.uiWindows.byGroup['right-menu-bar'], uiWindowID => state.uiWindows.byId[uiWindowID]),
		activeUIWindows: _.map(state.uiWindows.byGroup['main'], uiWindowID => state.uiWindows.byId[uiWindowID]),
		matchedRoute: state.adminState.matchedRoute?.path,
		viewport: state.adminState.viewport,
		hasPendingChanges: state.adminState.crdt.publishState !== PublishState.Published && state.adminState.crdt.publishState !== PublishState.Discarded,
		publishingAnimationActive: state.adminState.publishingAnimationActive,
		commerceWindowOpen: state.uiWindows.byId?.['commerce-window'] ? true : false,
		productFetchRequired: state.adminState.productFetchRequired,
		activePID: state.frontendState.activePID
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateAdminState: actions.updateAdminState,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(RightMenuBar));